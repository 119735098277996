import React from 'react';
import styled from 'styled-components';
import upload from "../../Images/upload.png";

export const Upload = () => {
  return (
    <Root>
      <h2>What have you been working on?</h2>

      <div className='upload_div'>
        <img src={upload} alt="upload_img"/>
        <h2>Click here to Upload</h2>
        <input type="file"/>
      </div>
    </Root>
  );
}

const Root = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  gap: 20px;

  .upload_div {
    position: relative; /* Added */
    width: 70%;
    height: 350px;
    border: 2px dashed #e7e7e9;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 100px;
      height: 100px;
    }

    h2 {
      font-size: 24px;
      font-weight: 600;
    }

    input[type="file"] {
      position:absolute;/* Added */
      width:100%;
      height:100%;
      top:0;
      left:0;
      opacity: 0; /* Hide default file input appearance */
      cursor: pointer; /* Keep the cursor as pointer */
    }
  }
`;
