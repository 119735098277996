import React, { useEffect } from "react";
import Sec1 from "./Sec1";
// import Sec2 from './Sec2'
import Sec3 from "./Sec3";
import Sec4 from "./Sec4";
import Sec5 from "./Sec5";
import Sec6 from "./Sec6";
import Sec7 from "./Sec7";
import Sec8 from "./Sec8";
import Sec10 from "./Sec10";
import Sec11 from "./Sec11";
import Sec14 from "./Sec14";
import Sec9 from "./Sec9";
import Sec13 from "./Sec13";
import Sec15 from "./Sec15";
import AOS from "aos";
import "aos/dist/aos.css";
import Testimonial from "../../CommonPages/HireIndianTalent/HireDeveloper/HireShopify/Testimonial";
import { Helmet } from "react-helmet";

function VideoMarketing() {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Customize duration as needed
  }, []);

  return (
    <div>
      <Helmet>
        <title>Offshore Video Marketing Services | Marketing Agency In India</title>

        <meta name="description" content="Phanom Professionals offers premium offshore video marketing services. Elevate your brand with engaging, impactful videos with the leading Marketing Agency In India." />
      </Helmet>
      <Sec1 />
      {/* <Sec2/> */}
      <Sec4 />
      <Sec6 />
      <Sec7 />
      <Testimonial />
      <Sec8 />
      <Sec9 />
      <Sec10 />
      <Sec13 />
      <Sec14 />
      <Sec15 />
      <Sec11 />
      <Sec5 />
      <Sec3 />
    </div>
  );
}

export default VideoMarketing;
