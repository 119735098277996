import React, { useEffect } from "react";
import Sec1 from "./Sec1";
import Sec2 from "./Sec2";
import Sec3 from "./Sec3";
import Sec4 from "./Sec4";
import Sec5 from "./Sec5";
import Sec6 from "./Sec6";
import Sec8 from "./Sec8";
import Sec7 from "./Sec7";
import Sec9 from "./Sec9";
import Sec10 from "./Sec10";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Testimonial from "../../CommonPages/HireIndianTalent/HireDeveloper/HireShopify/Testimonial";
import { Helmet } from "react-helmet";


function Wordpress() {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Customize duration as needed
  }, []);
  return (
    <div>

      <Helmet>
        <title> Offshore WordPress Development Company on India </title>
        <meta name="description" content="Phanom Professionals, a top offshore WordPress development company In India, offers custom design and development for interactive, user-friendly, results-driven websites." />
      </Helmet>

      <Sec1/>
      <Sec2/>
      <Sec8/>
      <Sec5/>
      <Testimonial/>
      <Sec3/>
      <Sec4/>
      <Sec9/>
      <Sec6/>
      <Sec10/>
      <Sec7/>
 
    </div>
  );
}

export default Wordpress;
