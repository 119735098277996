import React from 'react'
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import styled from 'styled-components';

export const Section7 = () => {
  return (
    <Root>
      <div className="container mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-12 col-12"></div>
          <div
            className="col-lg-8 col-md-12 col-12 text_div"data-aos="fade-up">
            <h2 className="first-content">Frequently <span style={{color:"#0b66c2"}}>Asked </span> Questions</h2>
          </div>
          <div className="col-lg-2 col-md-12 col-12"></div>
        </div>

      <div className='row'>
      <div className="col-lg-12 col-md-12 col-12">
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              1. How quickly can I get my website or software developed?

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            We understand the importance of time in your business. Depending on the complexity, our projects typically take between 4 to 8 weeks, but we always aim for efficiency without compromising quality.

            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              2. What if I’m unsure of my exact requirements?

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            No worries! We’ll work with you in the initial phase to gather and understand your requirements in detail, offering expert consultation to guide your project in the right direction.

            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              3. How do you ensure my website or application is scalable?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            We use modern technologies like ReactJS, NodeJS, and Next.js, which allow for seamless scaling as your business grows. We build a solid foundation that can handle increasing traffic and user demands.

            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              {" "}
              4. How do you handle security for my project?

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Security is our top priority. We integrate industry-standard security protocols at every stage, from backend development to server deployment, ensuring your data and business are always safe.

            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              5. What happens if I want to make changes after the project is completed?

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            We offer flexible post-launch support. Whether you need small adjustments or significant updates, our team is ready to help ensure your platform continues to meet your needs.

            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              6. Will my website or software be optimized for mobile devices?

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Absolutely! Every website or application we develop is responsive, meaning it works seamlessly on desktops, tablets, and mobile devices, enhancing the user experience across all platforms.

            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              7. Do you provide ongoing support and maintenance?


            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Yes, we offer various support packages to keep your platform running smoothly after deployment. Our team is available for updates, bug fixes, and regular maintenance.


            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              8. How do you ensure the final product meets my expectations?


            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            We follow a structured process that includes prototypes, Figma designs, and regular feedback loops. You'll be involved at every step, ensuring the final product aligns with your vision.


            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              9. What’s included in your free consultation?


            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Our free consultation includes a detailed discussion of your needs, analysis of your current system (if applicable), and a personalized solution plan tailored to your goals.


            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              10. What sets you apart from other developers?


            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
                We not only build solutions but partner with you throughout the journey. With deep expertise in custom development, we focus on building scalable, future-proof platforms tailored to your specific needs.

            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              11. Do you work with small businesses or only large corporations?


            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            We work with companies of all sizes, from startups to large enterprises. Our solutions are tailored to fit your budget and requirements, ensuring you get the best return on your investment.


            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              12. How do you charge for custom development projects?

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            We offer flexible pricing models, including fixed pricing for well-defined projects and hourly rates for ongoing development. During the consultation, we’ll help you determine the best model for your needs.


            </Typography>
          </AccordionDetails>
        </Accordion>



        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              13. Can I see examples of your previous work?


            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Absolutely! Check out our portfolio for case studies and examples of the work we've done for various clients across different industries.


            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              14. What technologies do you specialize in?


            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Our expertise spans ReactJS, NodeJS, Next.js, Shopify, WordPress, WooCommerce, and more. We choose the right technology based on your project’s needs and goals.


            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              15. How do you ensure the project stays within budget?


            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            We provide a detailed project plan and pricing structure upfront. Through regular communication and milestone reviews, we ensure your project stays on track both in terms of timelines and budget.


            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              16. What if my business needs evolve during the development process?


            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            We understand that business needs can change. Our process is flexible, and we can adapt to new requirements as long as they align with the scope of the project.


            </Typography>
          </AccordionDetails>
        </Accordion>



        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              17. Can you integrate third-party services or APIs into my platform?


            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Yes, we can integrate various third-party services, APIs, and payment gateways to ensure seamless functionality and enhanced user experience.

            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              18. Will I own the source code once the project is completed?


            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Yes, once the project is finished and paid for, you will have full ownership of the source code and all related assets.

            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              19. How do you handle website performance optimization?


            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            We prioritize speed and performance through efficient coding practices, optimized media, and using performance-enhancing tools. Our goal is to ensure fast load times and a smooth user experience.


            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              20. Do you offer SEO services for the websites you build?


            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>

            Yes, we ensure that all websites we develop are SEO-friendly, incorporating the best practices for search engine optimization from the start to boost your online visibility.


            </Typography>
          </AccordionDetails>
        </Accordion>
        
        </div>
        </div>
      </div>
    </Root>
  );
}


const Root = styled.section`
  background-color: #ffffffd4;
  padding: 0 100px;

  .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters.css-eqpfi5-MuiAccordionSummary-content
    p {
    font-size: 20px;
  }

  .MuiAccordionDetails-root.css-15v22id-MuiAccordionDetails-root p {
    font-size: 16px;
  }

  .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0 0px 0;
  }

  .css-15v22id-MuiAccordionDetails-root {
    padding: 0px 16px 16px;
  }

  .MuiAccordionSummary-expandIconWrapper.css-yw020d-MuiAccordionSummary-expandIconWrapper {
    color: #0b66c2;
  }

  .text_div {
    margin-bottom: 0.7em;
    text-align: center;
    .first-content {
      font-size: 45px;
      font-weight: bold;
      line-height: 1.3em;
      margin-right: 10px;
    }
   
  }

  @media (max-width: 567px) {
    padding: 0;
    .text_div {
      padding: 0;
    }

    .text_div .first-content {
    font-size: 35px;

}
  }

  @media (min-width: 567px) and (max-width: 992px) {
    padding: 0;
    .text_div {
      padding: 0;
    }
  }
`;